<template>
    <app-table
        :caption="$route.query.title"
        :columns="columns"
        :actions="tableActions"
        :url="`cdr/tariffs/${$route.params.id}`"
        height="calc(100vh - 194px)"
        :per-page="100"         
    ></app-table>
</template>

<script>
import AppTable from '@/components/Table.vue';

export default {
    components: {
        AppTable
    },
    data() {
        return {
            show: false,
            columns: [
                {
                    text: 'VR ID',
                    value: 'vr_id',
                    width: 100
                },
                {
                    text: 'Region',
                    value: 'region'
                },
                {
                    text: 'Flagfall',
                    value: 'flagfall_for_human',
                    sortable: false,
                    align: 'end',
                    width: 200
                },
                {
                    text: 'Per Min',
                    value: 'timed_for_human',
                    sortable: false,
                    align: 'end',
                    width: 200
                }
            ],
            tableActions: []
        };
    }
};
</script>
